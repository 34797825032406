import axios from "axios"
import { signInWithEmailAndPassword } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import React from "react"
import { auth, db } from "../../../firebase-config"
import { ENABLE_ERROR_LOGGING, ENDPOINT_128, ENDPOINT_LOCAL, ENDPOINT_PAYMENTS } from "../../settings/project-settings"

// [TO-DO] when subscription is cancelled now , it must be added to orders.canceled_at
// [TO-DO] when subscription is cancelled later, it must be added to orders.cancel_at


export default() => {
    const [currentUser, setCurrentUser] = React.useState("NOT LOGGED IN!")
    const [token, setToken] = React.useState("NOT LOGGED IN!")
    React.useEffect(()=>{
        auth.onAuthStateChanged(()=>{
            if( auth.currentUser ) setCurrentUser(auth.currentUser.uid)
            else setCurrentUser("failed to login")
            
            let anyAuth : any = auth
            if( anyAuth?.currentUser?.accessToken )
                setToken( anyAuth.currentUser.accessToken )
            
        })
        
    })

    return (
        <>
            <div> logged in as: {currentUser}</div>
            <div> logged in as: {JSON.stringify((auth as any)?.currentUser?.email)}</div>
            
            <QuickLogin email="nathanneuhaus@protonmail.com">
                Login
            </QuickLogin>
            
            <QuickButton token={token} endpoint={ENDPOINT_PAYMENTS} keyValue="test">
                Hello World
            </QuickButton>
         


            {/* 
      

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_128_B} 
                keyValue="set-created"
                params={{ newValue : true }}
                >
                
                set claims.created to true
            </QuickButton> */}
    


            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="check-version"
                params={{ newValue : 'valid' }}
            >
                check-version
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="check-history"
                params={{ newValue : 'valid' }}
            >
                check-history
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="reset-history"
                params={{ 
                    ...getTestTransaction()
                }}
            >
                reset-history
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="read-claims"
                params={{ 
                    ...getTestTransaction()
                }}
            >
                read-claims
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="create-transaction"
                params={{ 
                    ...getTestTransaction()
                }}
            >
                create-transaction
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="create-upgrade-transaction"
                params={{ 
                    link : 'some link number here'
                }}
            >
                create-upgrade
            </QuickButton>

            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_PAYMENTS} 
                keyValue="cancel-subscription"
                params={{ 
                    subscriptionId : '235-9789208-8347511' 
                    //423-4140152-5781191
                }}
            >
                cancel-subscription
            </QuickButton>

            <Button onClick={ async ()=>{
                const uid : string = "QoXsrlYSSPefUjpnNrz3EA2bPfj1"
                const ref = doc(db, `orders/${uid}`)
                const orders = (await getDoc(ref)).data()
                //const testOrders : any = getTestOrdersResponse()
                let subscriptions = parseSubscriptions( orders )
                const products = parseProducts( orders )
                if( ENABLE_ERROR_LOGGING ) console.log( orders )
                if( ENABLE_ERROR_LOGGING ) console.log( subscriptions )
                if( ENABLE_ERROR_LOGGING ) console.log( products )
            }}>
                Get Orders & Parse
            </Button>


            <QuickButton 
                token={token} 
                endpoint={ENDPOINT_LOCAL} 
                keyValue="test-restriction"
                params={{ 
                    //subscriptionId : '235-9789208-8347511' 
                    //423-4140152-5781191
                }}
            >
                test-restriction
            </QuickButton>

            

            {/* <QuickButton 
                token={token} 
                endpoint={ENDPOINT} 
                keyValue="cancel-subscription"
                //params={}
            >
                cancel-subscription
            </QuickButton> */}
            

            
           

            <br/>
            {token}
        </>
    )
}


export const parseSubscriptions = ( orders : any ) => {
    let subscriptions = {}
    Object.keys(orders).map( (keyValue : string ) => {
        let isSubscription = orders[keyValue].type === "subscription"
        if(!isSubscription) return
        subscriptions = {
            ...subscriptions,
            [keyValue] : {
                "amount": orders[keyValue].amount,
                "created": orders[keyValue].created,
                "active": orders[keyValue].active,
                "current_period_end": orders[keyValue].subscription.current_period_end,
                "current_period_start": orders[keyValue].subscription.current_period_start,
                "transaction": orders[keyValue].transaction,
                "canceled_at": orders[keyValue].canceled_at,
                "cancel_at": orders[keyValue].cancel_at
            }

        }
    })
    return subscriptions
}

export const parseProducts = ( orders : any ) => {
    let subscriptions = {}
    Object.keys(orders).map( (keyValue : string ) => {
        let isSubscription = orders[keyValue].type === "product"
        if(!isSubscription) return
        subscriptions = {
            ...subscriptions,
            [keyValue] : {
                "data": {
                    "name": orders[keyValue].order.name,
                    "back": orders[keyValue].order.back,
                    "id": orders[keyValue].order.id,
                    "front": orders[keyValue].order.front,
                    "colors": orders[keyValue].order.colors,
                    "desc": orders[keyValue].order.desc
                },
                "created": orders[keyValue].created,
                "transaction": orders[keyValue].transaction,
            }

        }
    })
    return subscriptions
}

export const getTestOrdersResponse = () => {

    return {
        "207-8548448-4700279": {
          "order": {
            "back": {
              "Title": "Slap Kard",
              "Footer Title": "We Mean Business",
              "Subtitle": "Smart Card"
            },
            "name": "Slap Kard Business",
            "front": {
              "Job Title": "Co-Founder",
              "City": "Everett",
              "Address": "14529 56th Ave SE",
              "First Name": "Mark",
              "Last Name": "Smith",
              "Website": "www.locustlabs.com",
              "Email": "info@space.com",
              "Phone": "(425) 337-0363",
              "Brand Name": "Sears",
              "State": "WA"
            },
            "colors": {
              "Background Color": "black",
              "Text Color": "white"
            },
            "id": "icy-inferno",
            "desc": "Icy Inferno"
          },
          "transaction": "pi_3MsuS7A2K1dGX0mD0Ahpgait",
          "shipping": {
            "priceId": "price_1LOniuA2K1dGX0mD9UTiLXOx",
            "amount": 999,
            "items": [
              {
                "amount": 3999
              }
            ]
          },
          "amount": 3999,
          "active": false,
          "created": 1680555491,
          "orderNumber": "207-8548448-4700279",
          "type": "product",
          "priceId": "price_1LIDUEA2K1dGX0mDIADVfjF4"
        },
        "423-4140152-5781191": {
          "orderNumber": "423-4140152-5781191",
          "active": true,
          "created": 1657032251,
          "priceId": "price_1LICz1A2K1dGX0mDFbxiJNAz",
          "subscription": {
            "current_period_end": 1712184074,
            "current_period_start": 1680561674
          },
          "transaction": "sub_1Msw3qA2K1dGX0mDyYM8KivV",
          "promotionId": "nPcfBUfR",
          "type": "subscription",
          "amount": 3999
        },
        "932-2926549-4901275": {
          "subscription": {
            "current_period_start": 1680555494,
            "current_period_end": 1712177894
          },
          "type": "subscription",
          "active": true,
          "transaction": "sub_1MsuSAA2K1dGX0mDZjwCBMj0",
          "orderNumber": "932-2926549-4901275",
          "priceId": "price_1LJdfWA2K1dGX0mD5gUb1KT0",
          "created": 1657373158,
          "amount": 699
        },
        "626-7932939-5562873": {
          "created": 1657373158,
          "type": "subscription",
          "subscription": {
            "current_period_start": 1680556614,
            "current_period_end": 1712179014
          },
          "orderNumber": "626-7932939-5562873",
          "active": true,
          "priceId": "price_1LJdfWA2K1dGX0mD5gUb1KT0",
          "amount": 699,
          "transaction": "sub_1MsukEA2K1dGX0mDYEEqyToY"
        },
        "119-4555334-0257436": {
          "orderNumber": "119-4555334-0257436",
          "type": "subscription",
          "created": 1657032251,
          "amount": 3999,
          "promotionId": "nPcfBUfR",
          "active": true,
          "priceId": "price_1LICz1A2K1dGX0mDFbxiJNAz",
          "subscription": {
            "current_period_start": 1680555493,
            "current_period_end": 1712177893
          },
          "transaction": "sub_1MsuS9A2K1dGX0mDyMIz79T6"
        },
        "745-1920110-8996960": {
          "promotionId": "nPcfBUfR",
          "amount": 3999,
          "transaction": "sub_1MsukCA2K1dGX0mD7EO5HKNb",
          "created": 1657032251,
          "type": "subscription",
          "orderNumber": "745-1920110-8996960",
          "priceId": "price_1LICz1A2K1dGX0mDFbxiJNAz",
          "active": true,
          "subscription": {
            "current_period_end": 1712179012,
            "current_period_start": 1680556612
          }
        },
        "276-7670820-2125260": {
          "order": {
            "id": "icy-inferno",
            "colors": {
              "Background Color": "black",
              "Text Color": "white"
            },
            "front": {
              "Last Name": "Smith",
              "Website": "www.locustlabs.com",
              "City": "Everett",
              "First Name": "Mark",
              "Phone": "(425) 337-0363",
              "Email": "info@space.com",
              "Address": "14529 56th Ave SE",
              "Job Title": "Co-Founder",
              "State": "WA",
              "Brand Name": "Sears"
            },
            "desc": "Icy Inferno",
            "name": "Slap Kard Business",
            "back": {
              "Footer Title": "We Mean Business",
              "Subtitle": "Smart Card",
              "Title": "Slap Kard"
            }
          },
          "priceId": "price_1LIDUEA2K1dGX0mDIADVfjF4",
          "transaction": "pi_3Msw3oA2K1dGX0mD0S5awm9l",
          "amount": 3999,
          "type": "product",
          "orderNumber": "276-7670820-2125260",
          "created": 1680561672,
          "shipping": {
            "priceId": "price_1LOniuA2K1dGX0mD9UTiLXOx",
            "items": [
              {
                "amount": 3999
              }
            ],
            "amount": 999
          },
          "active": false
        },
        "734-1996253-6535340": {
          "type": "product",
          "orderNumber": "734-1996253-6535340",
          "created": 1680556611,
          "shipping": {
            "amount": 999,
            "items": [
              {
                "amount": 3999
              }
            ],
            "priceId": "price_1LOniuA2K1dGX0mD9UTiLXOx"
          },
          "transaction": "pi_3MsukBA2K1dGX0mD1spT0Gj1",
          "active": false,
          "amount": 3999,
          "order": {
            "colors": {
              "Background Color": "black",
              "Text Color": "white"
            },
            "desc": "Icy Inferno",
            "name": "Slap Kard Business",
            "back": {
              "Title": "Slap Kard",
              "Subtitle": "Smart Card",
              "Footer Title": "We Mean Business"
            },
            "front": {
              "Phone": "(425) 337-0363",
              "Job Title": "Co-Founder",
              "Brand Name": "Sears",
              "Website": "www.locustlabs.com",
              "Address": "14529 56th Ave SE",
              "Email": "info@space.com",
              "City": "Everett",
              "First Name": "Mark",
              "Last Name": "Smith",
              "State": "WA"
            },
            "id": "icy-inferno"
          },
          "priceId": "price_1LIDUEA2K1dGX0mDIADVfjF4"
        },
        "235-9789208-8347511": {
          "subscription": {
            "current_period_start": 1680561675,
            "current_period_end": 1712184075
          },
          "orderNumber": "235-9789208-8347511",
          "priceId": "price_1LJdfWA2K1dGX0mD5gUb1KT0",
          "transaction": "sub_1Msw3sA2K1dGX0mD8jhPBHyK",
          "active": true,
          "amount": 699,
          "type": "subscription",
          "created": 1657373158
        }
      }


}


export const QuickLogin = ( props : { 
    children ?: any 
    email ?: string, 
    password ?: string, 
}) => {
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                signInWithEmailAndPassword(auth, props.email ? props.email : "newuser-test@email.com", props.password ? props.password : "password").then(( res )=>{
                    alert("success!")
                    if( ENABLE_ERROR_LOGGING ) console.log(res)
                })
            }}>
            {props.children}
        </button>
        
        </>
    )
}

export const QuickButton = ( props : { 
    children ?: any, 
    keyValue : string,
    params ?: any
    token?: string,
    endpoint?:string,
    config ?: any, 
    onSuccess ?: ( data : any ) => void 

}) => {

    const CONFIG = props.config ? props.config : {
        headers: { 
            Authorization: `Bearer ${props.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },crossDomain: true,
    };

    if( ENABLE_ERROR_LOGGING ) console.log(CONFIG)
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={ ()=>{
                
                axios.post( props.endpoint ? props.endpoint : ENDPOINT_128 , {
                    key : props.keyValue ? props.keyValue : '',
                    params : props.params ? props.params : { testParam : -1 }
                }, CONFIG )
                .then((response) => {
                    
                    if( props.onSuccess )
                        props.onSuccess( response )
                    else if( ENABLE_ERROR_LOGGING ) console.log(response.data);


                })
                .catch(e => {
                    if( ENABLE_ERROR_LOGGING ) console.log('Error: ', e)
                });
                
    
            }}>
            {props.children}
        </button>
        
        </>

    )
}






const Button = ( props : { 
    children ?: any, 
    onClick ?: any 
}) => {
    
    return (
        <>
            <div style={{ display : 'block'}}></div>
            <button 
            style={{ width : 200, height : 45 , marginBottom : 0, marginTop : 20 }}
            onClick={props.onClick}>
            {props.children}
        </button>
        
        </>

    )
}


export const getTestTransaction = () => {
    return {
        "email": "nateneuhaus@protonmail.com",
        "cardName": "John Doe",
        "cardNumber": "4242424242424242",
        "cardExpMonth": 7,
        "cardExpYear": 2023,
        "cardCvv": "314",
        "transaction": {
            "items": [
                {
                    "product": {
                        "type": "product",
                        "priceId": "price_1LIDUEA2K1dGX0mDIADVfjF4",
                        "order": {
                            "colors": {
                                "Background Color": "black",
                                "Text Color": "white"
                            },
                                "front": {
                                "First Name": "Mark",
                                "Last Name": "Smith",
                                "Job Title": "Co-Founder",
                                "Brand Name": "Sears",
                                "Address": "14529 56th Ave SE",
                                "City": "Everett",
                                "State": "WA",
                                "Phone": "(425) 337-0363",
                                "Email": "info@space.com",
                                "Website": "www.locustlabs.com"
                            },
                            "back": {
                                "Title": "Slap Kard",
                                "Subtitle": "Smart Card",
                                "Footer Title": "We Mean Business"
                            },
                            "name": "Slap Kard Business",
                            "id": "icy-inferno",
                            "desc": "Icy Inferno"
                        }
                    },
                    "name": "Slap Kard Business",
                    "desc": "Icy Inferno",
                    "id": "icy-inferno",
                    "protection": {
                        "type": "subscription",
                        "priceId": "price_1LJdfWA2K1dGX0mD5gUb1KT0"
                    },
                    "subscription": {
                        "type": "subscription",
                        "priceId": "price_1LICz1A2K1dGX0mDFbxiJNAz",
                        "promotionId": "nPcfBUfR"
                    }
                }
            ],
            "shipping": [
                "price_1LOniuA2K1dGX0mD9UTiLXOx"
            ],
            "allProducts": [
                {
                    "type": "product",
                    "priceId": "price_1LIDUEA2K1dGX0mDIADVfjF4",
                    "order": {
                        "colors": {
                            "Background Color": "black",
                            "Text Color": "white"
                        },
                        "front": {
                            "First Name": "Mark",
                            "Last Name": "Smith",
                            "Job Title": "Co-Founder",
                            "Brand Name": "Sears",
                            "Address": "14529 56th Ave SE",
                            "City": "Everett",
                            "State": "WA",
                            "Phone": "(425) 337-0363",
                            "Email": "info@space.com",
                            "Website": "www.locustlabs.com"
                        },
                        "back": {
                            "Title": "Slap Kard",
                            "Subtitle": "Smart Card",
                            "Footer Title": "We Mean Business"
                        },
                        "name": "Slap Kard Business",
                        "id": "icy-inferno",
                        "desc": "Icy Inferno"
                    }
                },
                {
                    "priceId": "price_1LOniuA2K1dGX0mD9UTiLXOx",
                    "type": "shipping"
                }
            ],
            "allSubscriptions": [
                {
                    "type": "subscription",
                    "priceId": "price_1LJdfWA2K1dGX0mD5gUb1KT0"
                },
                {
                    "type": "subscription",
                    "priceId": "price_1LICz1A2K1dGX0mDFbxiJNAz",
                    "promotionId": "nPcfBUfR"
                }
            ],
            "indempotency": "JA6V7h9wh1jOvnCBG2kI"
        }  
    }
}